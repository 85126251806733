<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 15:08:19
-->
<template>
  <div class="declaration"
    v-if="currentType && currentType.typeCode === '000001' && currentSubType && currentSubType.typeCode === '000008'">
    <div v-show="flag" class="contentsy">
      <div v-html="content"></div>
      <div>
        <p class="fd_title">中国房地产行业绿色供应链行动董事长签署的共同宣言</p>
      </div>
      <div class="flex">
        <vue-scroll ref="flow" @handle-scroll="handleScroll" :ops="ops">

          <div class="flow">
            <div v-for="item in list2" :key="item.articleId" class="prow " @click="findprowTitle(item.articleId)">
              <img class="" :src="item.imgUrl" alt="" style="cursor: pointer;">
              <div class="prowTitle ">
                <p class="newlineOmit">{{ item.articleName }}</p>
                <p>{{ item.summary.slice(0, 3) + "&nbsp;" + item.summary.slice(3) }}</p>
              </div>
            </div>
          </div>
        </vue-scroll>

      </div>
    </div>
    <div class="finds" v-show="!flag">
      <div class="fh">
        <el-button @click="goback">返回上级</el-button>
      </div>
      <img :src="facePic" alt="">
    </div>
  </div>
  <div v-else>
    <p class="hr"></p>
    <div>
      <div class="ofw contentsy">
        <div v-for="(item, index) in list" :key="index">
          <div v-if="list.length > 1" class="fx" :class="item.imgUrl ? 'htd' : 'htd-noimg'"
            @click="show(item.articleId, index)">
            <div class="image mt10" v-if="item.imgUrl">
              <img class="image" :src="item.imgUrl || require('@/assets/logo1.png')" alt="">
            </div>
            <div class="date ">
              <p class="ys">{{ dateFormat(item.createdat, 'MM-dd') }}</p>
              <p class="ys1">{{ dateFormat(item.createdat, 'yyyy') }}</p>
              <!-- <p class="ys2">{{ item.authorName }}</p> -->
              <p class="brder"></p>
            </div>
            <div class="row">
              <div class="row_1">{{ item.articleName.length > 25 ? item.articleName.slice(0, 22) + '...' :
      item.articleName
                }}</div>
              <!-- <div class="row_2" v-html="item.content"></div> -->
            </div>
            <div v-if="show3 == index" class="adds">-</div>
            <div v-else class="adds">+</div>
          </div>
          <el-collapse-transition>
            <div v-show="show3 == index">
              <div class="main flex">
                <div class="file" v-for="(val, ins) in item.nodeVoList" :key="ins">
                  <span>附件{{ ins + 1 }}：</span>
                  <span @click="dldFile(val.noteId)" class="filecolor pointer">{{ val.nodeName }}</span>
                </div>
              </div>
              <div class="realContent" v-html="item.content"></div>
            </div>
          </el-collapse-transition>

        </div>
        <div class="prompt" v-if="list.length == 0">
          {{ isEnglish ? 'NO DATA' : '暂无数据' }}
        </div>
      </div>
    </div>
    <div class="fx jct">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage1" :page-size="6" prev-text="Prev" next-text="Next" layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import "@/css/elememtUi.less";
import { articleListByPage, articleDetail } from '@/api'
import dateFormat from '@/utils/time'
import '@/css/content.less'
import { mapGetters } from 'vuex';
import vuescrolljs from '@/mixin/index.js'
export default {
  mixins: [vuescrolljs],
  data() {
    return {
      show3: 0,
      dateFormat,
      headerNmaeCh: '<p style="color: #57802b;">News</p>',
      headerNmae:
        '<p style="color: #57802b; letter-spacing:2px; font-size: 35px;">新闻动态</p>',
      list: [],
      content: '',
      flag: false,
      currentPage1: 1,
      pages: {
        page: 1,
        limit: 9
      },
      total: 0,
      dateY: dateFormat(+new Date(), 'yyyy'),
      list2: [],
      facePic: null,
    };
  },
  computed: {
    ...mapGetters(['isEnglish', 'currentType', 'currentSubType'])
  },
  watch: {
    '$route'(newValue, oldValue) {
      this.articleListByPage();
      if (this.currentType && this.currentType.typeCode === '000001' && this.currentSubType && this.currentSubType.typeCode === '000008') {
        this.flag = true;
        this.getApiList()
      }
    }
  },
  mounted() {
    this.hub.$emit('theCover', this.$route.meta.flagNam)
    // this.dateY = this.$route.query.dateY
    this.hub.$emit('scrollToz')
    this.hub.$off('dateY');
    this.hub.$on('dateY', () => {
      this.dateY = this.$route.query.dateY
      this.articleListByPage()
    })
    // this.hub.$off('currentSubType');
    // this.hub.$on('currentSubType', () => {
    //   this.articleListByPage()
    // })
    this.hub.$off('changeLanguage');
    this.hub.$on('changeLanguage', () => {
      this.articleListByPage()
    })
    console.log('id' in this.$route.query);
    console.log(this.$route.query);
    if ('id' in this.$route.query) {
      this.findS(this.$route.query.id)
    } else {
      this.articleListByPage()
    }
    if (this.currentType && this.currentType.typeCode === '000001' && this.currentSubType && this.currentSubType.typeCode === '000008') {
      this.flag = true;
      this.getApiList()
    }
  },
  methods: {
    articleListByPage() {
      const params = {
        ...this.pages,
        catCode: this.currentSubType ? `${this.currentType.typeCode}%7C${this.currentSubType.sysCode}` : this.currentType.typeCode,
        isEn: this.isEnglish ? 1 : 0
      }
      this.list = [];
      articleListByPage(params).then((res) => {
        console.log(res.data);
        if (this.currentType.typeCode === '000006') {
          if (this.currentSubType.sysCode === '000049') {
            this.list = this.isEnglish ? [res.data.list[1]]:[res.data.list[0]];
          }
          else {
            if (res.data.list.length > 1) {
              this.list = this.isEnglish ? [res.data.list[0]]: [res.data.list[1]];
            }
            else {
              this.list = res.data.list;
            }
          }
        }
        else {
          this.list = res.data.list
        }
        // list = list.filter(v=>v.articleTypeName === `${this.currentType}`)
        // this.list = res.data.list
        this.total = res.data.total
        if (this.list.length >= 1) {
          let _articleId = this.list[0].articleId
          this.list = this.list.map((item, i) => {
            if (0 == i && item.articleId == _articleId) {
              const params1 = {
                articleId: _articleId,
                isEn: this.isEnglish ? 1 : 0
              };
              this.loading = true;
              articleDetail(params1).then((res) => {
                this.loading = false;
                this.content = res.data.content;
                item.nodeVoList = res.data.nodeVoList || [];
              });
            }
            return item
          })
        }
      })
    },
    dldFile(id) {
      window.location.href =
        `http://gsc.see.org.cn/file/file/api/download?route=file&nodeId=` + id;
    },
    show(id, index) {
      if (index == this.show3) {
        this.show3 = -1
        return
      }
      this.show3 = index
      this.list = this.list.map((item, i) => {
        if (index == i && item.articleId == id) {
          const params1 = {
            articleId: id,
            isEn: this.isEnglish ? 1 : 0
          };
          this.loading = true;
          articleDetail(params1).then((res) => {
            this.loading = false;
            item.nodeVoList = res.data.nodeVoList || [];
          });
        }
        return item
      })

    },
    findS(code) {
      const params = {
        articleId: code,
        isEn: this.isEnglish ? 1 : 0
      }
      articleDetail(params).then((res) => {
        this.list = [res.data]
        this.hub.$emit('dayeYYYY', dateFormat(res.data.createdat, 'yyyy'))
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pages.page = val
      this.articleListByPage()
    },
    getApiList() {
      const params1 = {
        ...this.pages,
        catCode: "000001%7C000037",
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleListByPage(params1).then((res) => {
        console.log(res.data);
        this.list2 = res.data.list
        this.total = res.data.total
      })
    },

    handleScroll(vertical, horizontal, nativeEvent) {
      const flowarr = this.$refs["flow"].getScrollProcess();


      if (+flowarr.v.toFixed(1) > 0.8) {
        this.pages.limit = this.pages.limit + 9
        this.getApiList()
      }


    },
    findprowTitle(id) {
      const params = {
        articleId: id,
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        console.log(res.data.imgUrl);
        this.facePic = res.data.imgUrl
        this.flag = false
        this.hub.$emit('scrollToz')
      })
    },
    goback() {
      this.flag = true
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  margin-top: 30px;
}

.prompt {
  text-align: center;
  line-height: 80px;
  font-size: 22px;
  color: #666;
}

.hr {
  margin-top: 34px;
  margin-right: 22px;
  margin-bottom: 10px;
  border-top: 2px solid #57802b;
}

.fx {
  display: flex;
}

.jct {
  padding-top: 20px;
  height: 60px;
  justify-content: right;
  margin-right: 30px;
}

.htd {
  height: 230px;
  width: 98%;
  border-bottom: 1px solid #b4c56a;
}

.htd-noimg {
  height: 100px;
  width: 98%;
  border-bottom: 1px solid #b4c56a;
}

.image {
  width: 312px;
  height: 180px;
  background: #f3f3f347;
}

.mt10 {
  margin-top: 20px;
}

.date {
  margin-top: 20px;
  padding-left: 20px;

  width: 180px;

  position: relative;

  .ys {
    // margin-top: 6px;
    color: #57802b;
    font-size: 30px;
    font-weight: 700;
  }

  .ys1 {
    color: #cfd0d0;
    font-size: 16px;
  }

  .ys2 {
    position: absolute;
    top: 135px;
    left: 26px;
    color: #6f7070;
    font-size: 18px;
  }

  .brder {
    width: 20px;
    height: 40px;
    position: absolute;
    top: 75px;
    left: 26px;
    border-bottom: 1px solid #cfd0d0;
  }
}

.row {
  flex: 1;
  width: 520px;

  //   color: #8c8887;
}

.row_1 {
  margin-top: 10px;
  width: 100%;
  //   padding-left: 35px;
  line-height: 60px;
  font-size: 20px;
  font-weight: bold;
}

.row_2 {
  height: 118px;
  width: 100%;
  padding-right: 20px;

  overflow: hidden;
  color: #8e8d8e;
}

.row_3 {
  height: 118px;
  width: 100%;
  padding-right: 20px;

  overflow: hidden;
  color: #8e8d8e;
}

.adds {
  margin-top: 15px;
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border: 1px solid #b4c56a;
  border-radius: 50%;
  color: #b4c56a;
  position: relative;
  top: 20px;
  left: -8px;
}

.ofw {
  width: 1048px;
  // height: 750px;
  // overflow: auto;
}

.main {
  min-height: 20px;

  color: #5e5d5d;

  .file {
    margin: 10px;
    width: 30%;
  }

  .filecolor {
    color: #337ab7;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.contentsy {
  color: #5e5d5d;
  font-size: 18px;
  line-height: 1.5;

  p {
    span {
      font-size: 18px !important;
      text-indent: 2em !important;
    }
  }
}

.realContent /deep/ img {
  max-width: 100%;
}

.declaration {
  .fd_title {
    text-align: center;
    font-size: 18px;
    background: #5f7f0d;
    font-weight: 700;
    color: #fff;
  }

  .flex {
    height: 1000px;
    overflow: auto;

    .flow {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .prow {
      width: 266px;
      height: 368px;
      text-align: center;
      margin: 10px 0;

      img {
        width: 234px;
        height: 296px;
      }

      .prowTitle {
        margin: 0;
        background: #97c619;
        color: #fff;
        font-size: 18px;

        p {
          height: 30px;

          &:nth-child(2) {
            height: 36px;
          }
        }
      }
    }
  }

  .finds {
    img {
      width: 100%;
    }
  }

  .fh {
    position: relative;
    height: 0;
    top: -120px;
    right: 50px;
    float: right;

    button {
      border: 1px solid #5a6f18;
      color: #5a6f18;
    }
  }

  .newlineOmit {
    height: 30px;
  }
}
</style>
